import { Paper } from "@material-ui/core";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { baseUrl } from "../../../services/config";
import Select from "react-select";
import { useAlert } from "react-alert";
import { Spinner, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import PasswordModal from "../../../components/PasswordModal/PasswordModal";
import "./receiving.css";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";
const ReceivingPaySheet = () => {
  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];
  const [selected, setSelected] = useState("campus");
  const [month, setMonth] = useState("");
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [campus, setCampus] = useState([]);
  const [allBanks, setAllBanks] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [fullCampus, setFullCampus] = useState({});
  const [bankId, setBankId] = useState("");
  const [cheque, setCheque] = useState("");
  const [loading, setLoading] = useState(false);
  const [grandTotal, setgrandTotal] = useState("");
  const [printAbleData, setPrintAbleData] = useState([]);
  const [allcheckboxes, setallcheckboxes] = useState([]);
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  const [sessionData, setSessionData] = useState([]);
  const [yearId, setYearId] = useState("");
  const pageStyle = `
  @page {
    size: legal portrait !important;
    margin-bottom: 1in;
  }
  `;
  const alert = useAlert();

  let authtoken = localStorage.getItem("userToken");

  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [directorSign, setDirectorSign] = useState(0);
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getAllBanks();
    getSettingData();
    sessions();
  }, []);

  const onRadioButtonClick = (e) => {
    setReportData([]);
    setSelected(e.target.value);
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUnitName(upperCase(data.payload.settings.unit_name));
        setDirectorSign(data.payload.settings.director_sign);
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessions = () => {
    fetch(`${baseUrl}/session`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSessionData(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setYearId(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllBanks = () => {
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        if (data.metadata) {
          if (data.metadata.success) {
            data.payload.forEach((item) => {
              item["label"] = item.title;
              item["options"] = item.bank_accounts;
              item.bank_accounts.forEach((subitem) => {
                subitem.label = subitem.bank_name;
                subitem.value = subitem.id;
              });
            });
            setAllBanks(data.payload);
          }
        }
        console.log(data.payload, "changed data ");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddCampusHandler = () => {
    if (selected === "campus") {
      if (campus == "") {
        alert.error("Campus Is Required");
        return;
      }

      if (bankId == "") {
        alert.error("Bank Is Required");
        return;
      }
    } else {
      if (cheque == "") {
        alert.error("Cheque no is Required");
        return;
      }
    }

    if (month == "") {
      alert.error("Month Is Required");
      return;
    }

    setLoading(true);
    const data = {
      date: `${month}-01`,
      year_id: yearId,
      cheque_no: selected == "cheque" ? cheque : "",
      campus_id: selected == "campus" ? campus : "",
      bank_id: selected == "campus" ? bankId : "",
    };

    fetch(`${baseUrl}/monthly-pay-sheet`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) =>
        res.json().then((data) => {
          setLoading(false);
          console.log(data);
          // let sum=0
          // data.payload.map((item)=>{
          //  sum+=parseInt(item.net_pay)
          // })
          // setgrandTotal(sum)

          setReportData(data.payload);
        })
      )
      .catch((err) => {
        setLoading(false);
      });
  };

  function formatDate(inputDate) {
    const parts = inputDate.split("-");
    const month = parseInt(parts[1], 10);
    const year = parts[0];

    // Create an array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Get the full month name
    const fullMonth = monthNames[month - 1];

    // Format the result as "Month, Year"
    const formattedDate = `${fullMonth}, ${year}`;

    return formattedDate;
  }

  const changeDate1 = () => {
    var yourDate = new Date(month);
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var year = yourDate.getFullYear();
    var monthIndex = yourDate.getMonth();
    var day = yourDate.getDate();

    var formattedDate = months[monthIndex] + " " + day + ", " + year;

    return formattedDate;
  };
  const formatdate2 = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  function inWords(num) {
    let a = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    let b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    let str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lac "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]])
        : "";
    console.log(str);
    return str;
  }

  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  ////for checkbox logic
  const allcheckboxChecked = () => {
    let selectedRows = [];
    let middlearray = [];
    if (checkallcheckbox) {
      setcheckallcheckbox(false);
      reportData.map((item, i) => {
        item.checked = false;
        middlearray.push(item);
      });
    } else {
      setcheckallcheckbox(true);
      reportData.map((item, i) => {
        item.checked = true;
        selectedRows.push(item);
        middlearray.push(item);
      });
    }
    console.log(selectedRows);
    console.log(middlearray);
    setPrintAbleData([...selectedRows]);
  };

  const checkBoxClick = (e, index) => {
    setcheckallcheckbox(true);
    let middlearray = [];
    let selectedRows = [];
    reportData.map((item, i) => {
      if (item.emp_code == index) {
        item.checked = !item.checked;
      }
      if (!item.checked) {
        setcheckallcheckbox(false);
      }
      if (item.checked) {
        selectedRows.push(item);
      }
      middlearray.push(item);
    });

    console.log(selectedRows);
    setPrintAbleData([...selectedRows]);
  };

  console.log(printAbleData, "this is selected Onea");
  const makeItSum = (arr) => {
    let sum = 0;
    arr.map((item) => {
      sum += parseInt(item.net_pay);
    });
    return sum;
  };
  return (
    <>
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-md-6 text-center">
            <input
              checked={selected == "cheque"}
              onChange={(e) => onRadioButtonClick(e)}
              defaultChecked
              type="radio"
              id="cheque"
              value="cheque"
            />
            <label for="cheque">Cheque Wise</label>
          </div>

          <div className="col-md-6 text-center">
            <input
              checked={selected == "campus"}
              onChange={(e) => onRadioButtonClick(e)}
              type="radio"
              id="Campus"
              value="campus"
            />
            <label for="Campus">Campus Wise</label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 mt-2">
            <label className="form-control-label">
              <b>
                Select Session
                <span style={{ color: "red", fontSize: 12 }}>*</span>
              </b>
            </label>
            <select
              style={{ height: 40 }}
              className="form-select"
              name="year_id"
              value={yearId}
              aria-label="Default select example"
              onChange={(e) => setYearId(e.target.value)}
            >
              <option selected value="">
                Select Session
              </option>
              {sessionData &&
                sessionData.map((item) => (
                  <option value={item.id}>{item.year}</option>
                ))}
            </select>
          </div>
          {selected == "cheque" ? (
            <div className="col-md-3">
              <label>
                <b>Enter Cheque Number</b>
              </label>
              <input
                onChange={(e) => setCheque(e.target.value)}
                type="text"
                className="form-control"
                placeholder="Enter Cheque Number"
              />
            </div>
          ) : (
            ""
          )}
          {selected == "campus" ? (
            <>
              <div className="col-md-3">
                <label>
                  <b>Select Campus</b>
                </label>
                <div className="d-flex flex-column">
                  <select
                    style={{ height: 40 }}
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => {
                      let res = campusData.find(
                        (item) => item.id == e.target.value
                      );
                      setFullCampus(res);
                      setCampus(e.target.value);
                    }}
                  >
                    <option selected value="">
                      {" "}
                      Select Campus
                    </option>
                    {campusData &&
                      campusData.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <label>
                  Bank <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  options={allBanks}
                  onChange={(e) => setBankId(e.value)}
                  // formatGroupLabel={formatGroupLabel}
                />
              </div>
            </>
          ) : (
            ""
          )}

          <div className="col-md-3">
            <div>
              <label>
                <b>Select Month</b>
              </label>
            </div>
            <input
              value={month}
              onChange={(e) => {
                setMonth(e.target.value);
              }}
              className="form-control"
              type="month"
            />
          </div>
          <div className="col-md-4 mt-9">
            <button className="btn btn-primary" onClick={AddCampusHandler}>
              Show
            </button>
            {printAbleData.length > 0 ? (
              <ReactToPrint
                documentTitle={`Pay Salary`}
                pageStyle={pageStyle}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    style={{ marginLeft: "10px" }}
                    className="btn btn-success button_blue button_blue "
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner class="text-center" variant="info" animation="border" />
          </div>
        ) : (
          <div id="tableboot">
            <Table responsive>
              <thead>
                <tr id="tbl">
                  <th>
                    <input type="checkbox" style={{ display: "none" }} />
                    <label className="checkbox checkbox-single">
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          allcheckboxChecked();
                        }}
                        checked={checkallcheckbox}
                      />
                      <span />
                    </label>
                  </th>
                  <th className="text-center">SNo.</th>
                  <th className="text-center">Code</th>
                  <th>EMPLOYEE NAME</th>
                  <th className="text-center">PAID</th>
                  <th className="text-center">ACCOUNT NO.</th>
                </tr>
              </thead>

              <tbody>
                {!!reportData &&
                  reportData.map((item, index) => (
                    <tr id="tbl">
                      <td style={{ textAlign: "start" }}>
                        <input type="checkbox" style={{ display: "none" }} />
                        <label className="checkbox checkbox-single">
                          <input
                            type="checkbox"
                            key={item.emp_code}
                            onClick={(e) => {
                              checkBoxClick(e, item.emp_code);
                            }}
                            checked={item.checked}
                            value={allcheckboxes[index]}
                          />
                          <span />
                        </label>
                      </td>
                      <td style={{ color: "black" }} className="text-center">
                        {index + 1}
                      </td>
                      <td style={{ color: "black" }} className="text-center">
                        {item.emp_code}
                      </td>
                      <td
                        style={{ color: "black" }}
                        className="text_align_start"
                      >
                        {item.full_name}
                      </td>

                      <td style={{ color: "black" }} className="text-center">
                        {item.net_pay}
                      </td>
                      <td style={{ color: "black" }} className="text-center">
                        {item.account_no}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        )}

        {printAbleData.length > 0 ? (
          <div
            ref={componentRef}
            id="printSection"
            style={{ visibility: "hidden" }}
            className="tablePrint"
          >
            <div className="p-3" style={{ display: "flex" }}>
              <img
                // className='col-md-2'
                style={{ height: 110, width: 120 }}
                src={icon}
              />

              <div className="col-md-8" style={{ paddingTop: "5px" }}>
                <h1>{unitName}</h1>
                <p className="paybodyFont nextmoreFont">
                  <b>
                    PAY SHEET FOR THE MONTH OF {upperCase(formatDate(month))}
                  </b>
                </p>
                <div
                  className="cheqanddated"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="text-end">
                    <b className="paybodyFont nextmoreFont">Cheque No:-</b>
                    &nbsp;&nbsp;&nbsp;
                    <span className="paybodyFont  nextmoreFont">
                      {printAbleData[0].cheque_number}
                    </span>
                  </div>

                  <div className="text-center">
                    <b className="paybodyFont"></b>&nbsp;&nbsp;&nbsp;
                    <span className="paybodyFont nextmoreFont">
                      <b>{fullCampus?.name}</b>
                    </span>
                  </div>
                  <div className="text-end">
                    <b className="paybodyFont nextmoreFont">Dated:-</b>{" "}
                    &nbsp;&nbsp;&nbsp;
                    <span className="paybodyFont nextmoreFont">
                      {formatdate2()}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <table style={{ width: "100%", marginTop: "10px" }}>
              <thead>
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      color: "black",
                      fontSize: "18px",
                    }}
                  >
                    <b>SNo.</b>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      color: "black",
                      fontSize: "18px",
                    }}
                  >
                    <b>CODE</b>
                  </td>
                  <td
                    style={{
                      textAlign: "start",
                      border: "1px solid black",
                      paddingLeft: "5px",
                      color: "black",
                      fontSize: "18px",
                    }}
                  >
                    <b>EMPLOYEE NAME</b>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      color: "black",
                      fontSize: "18px",
                    }}
                  >
                    <b>PAID</b>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      paddingLeft: "5px",
                      color: "black",
                      fontSize: "18px",
                    }}
                  >
                    <b>ACCOUNT NO.</b>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      border: "1px solid black",
                      paddingLeft: "5px",
                      color: "black",
                      fontSize: "18px",
                    }}
                  >
                    <b>SIGNATURE</b>
                  </td>
                </tr>
              </thead>
              <tbody>
                {printAbleData.map((item, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                            color: "black",
                            fontSize: "16px",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                            color: "black",
                            fontSize: "16px",
                          }}
                        >
                          {item.emp_code}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            border: "1px solid black",
                            paddingLeft: "5px",
                            color: "black",
                            fontSize: "16px",
                          }}
                        >
                          {item.full_name}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                            color: "black",
                            fontSize: "16px",
                          }}
                        >
                          {item.net_pay}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            border: "1px solid black",
                            paddingLeft: "5px",
                            color: "black",
                            fontSize: "16px",
                          }}
                        >
                          {item.account_no}
                        </td>
                        <td
                          style={{
                            textAlign: "start",
                            width: "150px",
                            border: "1px solid black",
                            paddingLeft: "5px",
                            color: "black",
                            fontSize: "16px",
                          }}
                        ></td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            <div className="totalOfPaidSalary2">
              <div className="d-flex">
                <p>
                  <b>In Words:</b>
                </p>
                <p style={{ paddingLeft: "15px" }}>
                  {" "}
                  {inWords(parseInt(makeItSum(printAbleData)))}
                </p>
              </div>
              <div className="d-flex">
                <p>
                  <b>Total:</b>
                </p>
                <p style={{ paddingLeft: "15px" }}>
                  {" "}
                  {makeItSum(printAbleData)}
                </p>
              </div>
            </div>
            <div
              className=""
              style={{
                marginTop: "80px",
                display: "flex",
                justifyContent: "space-between",
                width: "70%",
              }}
            >
              <div className="">
                <p>
                  <b
                    style={{
                      color: "black",
                      fontSize: "18px",
                      paddingRight: "30px",
                      paddingLeft: "30px",
                      borderTop: "1px solid black",
                    }}
                  >
                    Approved By
                  </b>
                </p>
              </div>
              <div className="" style={{ marginLeft: "50px" }}>
                {directorSign == 0 ? (
                  <p>
                    <b
                      style={{
                        color: "black",
                        fontSize: "18px",
                        paddingRight: "30px",
                        paddingLeft: "30px",
                        borderTop: "1px solid black",
                      }}
                    >
                      Chief Executive / Principal
                    </b>
                  </p>
                ) : (
                  <p>
                    <b
                      style={{
                        color: "black",
                        fontSize: "18px",
                        paddingRight: "30px",
                        paddingLeft: "30px",
                        borderTop: "1px solid black",
                      }}
                    >
                      Director / Principal
                    </b>
                  </p>
                )}
              </div>
              {/* <div className="col-md-4">
           <p><b style={{color:"black",fontSize:"16px"}}>Chief Executive </b></p>

           </div> */}
            </div>
          </div>
        ) : (
          ""
        )}
      </Paper>
      {/* {loginAccount == "Campus" ? <PasswordModal /> : ""} */}
    </>
  );
};

export default ReceivingPaySheet;
